import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Arrow from "../images/arrow.inline.svg";
import IconOne from "../images/icon5.inline.svg";
import IconTwo from "../images/icon2.inline.svg";
import IconThree from "../images/icon1.inline.svg";
import IconFour from "../images/one.inline.svg";
import IconFive from "../images/icon4.inline.svg";
import IconSix from "../images/icon3.inline.svg";

import IconEight from "../images/two.inline.svg";
import IconNine from "../images/icon9.inline.svg";

import Company from "../images/leveridcrop_new_two.inline.svg";

function IndexPage({ data, pageContext: { lang, currentPath } }) {
  return (
    <Layout>
      <SEO
        title="Corporate Solutions"
        description="LeverID is a modular solution that allows businesses of any size to effortlessly onboard and authenticate employees."
        image="https://www.leverid.com/thumbnail-new.png"
      />
      <Container className="home-first fullwidth banner-section regularheader">
        <Row className="container">
          <Col>
            <h4>CORPORATIONS</h4>
            <h1>A Borderless Authentication Solution for Businesses</h1>
            <p>
              LeverID is a modular solution that allows businesses of any size
              to effortlessly onboard and authenticate employees, as well as
              provide transparency and worker accountability.
            </p>
            <Link to="/contact">
              Get in touch
              <Arrow />
            </Link>
          </Col>
          <Col className="bannerimage">
            <Img fluid={data.conone.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>

      <Container className="home-third  fullwidth bluelines">
        <Container>
          <Row>
            <h2>
              Why do Corporations
              <br />
              choose LeverID?
            </h2>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconOne />
              </div>
              <h4>Post-Quantum Capable Design</h4>
              <p>
                The advent of quantum computing brings new security challenges.
                With this in mind, LeverID has been tailored to feature quantum
                capability.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconFour />
              </div>
              <h4>Enhanced Employee Onboarding</h4>
              <p>
                Providing employees from different countries with single
                authentication and controlled access to relevant information and
                documentation.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconThree />
              </div>
              <h4>Attack-Tolerant and Reliable</h4>
              <p>
                LeverID features distributed private key implementation,
                ensuring no service breaks and reliable defense against threats.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconTwo />
              </div>
              <h4>Flexible & Scalable</h4>
              <p>
                Whether your company employs 10 or 10 million, is domestic or
                global, our solution scales effortlessly to meet your demands.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconEight />
              </div>
              <h4>Data Security & Ownership</h4>
              <p>
                Employee data is securely stored and managed in-line with GDPR
                regulations, and is protected from unscrupulous access.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconNine />
              </div>
              <h4>Universal Login</h4>
              <p>
                Enable employees access to different company services and
                platforms using one universal login.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="element-icon">
                <IconFive />
              </div>
              <h4>Ease of Integration</h4>
              <p>
                Being technically documented, ease of integration is enabled
                through API or a software development kit (SDK) for clients
                wishing to integrate LeverID.
              </p>
            </Col>
            <Col>
              <div className="element-icon">
                <IconSix />
              </div>
              <h4>Mobile-First Design</h4>
              <p>
                Designed with mobile users in mind, our solution allows our
                users to effortlessly authenticate and sign from anywhere in the
                world.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="fullwidth government-graph">
        <Container>
          <Row>
            <h3>Who can benefit from LeverID?</h3>
            <p>
              The processess of different business verticals vary, however they
              all require some form of authentication and accountability.
              LeverID provides a universal platform to deliver these benefits.
            </p>
            <Company />
            <p className="bottom-graph">
              The application of LeverID extends far beyond the business
              verticals shown.
            </p>
          </Row>
        </Container>
      </Container>

      <Container className="fullwidth home-fift centered-text">
        <Container>
          <Row>
            <Col>
              <h3>Compatible with today’s signature standards</h3>
              <p>
                LeverID is compatible with technologies and applications that
                require RSA and EdDSA verifiable digital signatures, allowing
                for <strong>trustworthy employee authentication.</strong>
              </p>
            </Col>
            <Col>
              <Img fluid={data.contwo.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Img fluid={data.cothree.childImageSharp.fluid} />
            </Col>
            <Col>
              <h3>Prevent Malicious Activity</h3>
              <p>
                Organizations of all sizes can be targeted and fall victim to
                impersonation crimes due to improper identity verification
                processes. LeverID helps you{" "}
                <strong>minimize the risk of malicious activity</strong> in your
                corporation.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Based on Patent-Pending Technology</h3>
              <p>
                LeverID is based on patent-pending technology, thereby forging
                the path for{" "}
                <strong>
                  new industry standards, as well as yielding sustainability.
                </strong>
              </p>
            </Col>
            <Col>
              <Img fluid={data.goverone.childImageSharp.fluid} />
            </Col>
          </Row>
        </Container>
      </Container>
      <div id="prefooter">
        <Container>
          <Row>
            <Col>
              <Img fluid={data.rihofull.childImageSharp.fluid} />
            </Col>
            <Col>
              <h3>
                Explore how your business
                <br /> can reap the benefits of LeverID
              </h3>
              <p>
                To learn more about how LeverID could serve your needs, please
                reach out using the button below.
              </p>
              <Link to="/contact">
                Contact us <Arrow />
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rihofull: file(relativePath: { eq: "riho_full.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    goverone: file(relativePath: { eq: "goverone.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    conone: file(relativePath: { eq: "coone.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contwo: file(relativePath: { eq: "cotwo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cothree: file(relativePath: { eq: "cothree.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default IndexPage;
